import { api } from "./query-interceptors";

/**
 * Attempt to log in, posting a new login to the API
 * @param {LoginForm} loginForm - The current state of the login form fields
 * @return {Promise} - Returns the axios request promise.
 */
export async function loginPostQuery(loginForm) {
    const requestOptions = {
        method: 'POST',
        url: `/logins/`,
        data: loginForm
    };
    return api(requestOptions);
}

/**
 * Retrieve info associated with a previously existing login
 * @param {string} loginId - The uuid of the login session (expressed as a string) to retrieve from the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function loginGetQuery(loginId, controller) {
    const expandString = 'user.accountUserRelations';

    const requestOptions = {
        method: 'GET',
        url: `/logins/${loginId}/`,
        params: {
            expand: expandString
        },
        signal: controller.signal
    };
    return api(requestOptions);
}