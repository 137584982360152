import { useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useErrorBoundary } from "react-error-boundary";
import { accountIdAtom, batchIdAtom, loginFormAtom, loginStatusAtom, projectIdAtom, userAtom } from '_atoms';
import {
    appendToPreviousStatusCodes,
    setStateAfterSuccessfulLogin,
    setLoginStatusAfterFailedLogin
} from "_helpers/login-helpers";
import { loginPostQuery } from "_queries/login-queries";

/**
 * Actions to log in or log out.
 */
const useLoginActions = () => {
    /** @type LoginForm */
    const loginForm = useRecoilValue(loginFormAtom);
    /** @type Dispatch<SetStateAction<string>> */
    const setAccountId = useSetRecoilState(accountIdAtom);
    /** @type Dispatch<SetStateAction<string>> */
    const setBatchId = useSetRecoilState(batchIdAtom);
    /** @type Dispatch<SetStateAction<string>> */
    const setProjectId = useSetRecoilState(projectIdAtom);
    /** @type Dispatch<SetStateAction<User>> */
    const setUser = useSetRecoilState(userAtom);
    /** @type Dispatch<SetStateAction<LoginStatus>> */
    const setLoginStatus = useSetRecoilState(loginStatusAtom);
    /** @type function */
    const resetUser = useResetRecoilState(userAtom);
    /** @type function */
    const resetLoginForm = useResetRecoilState(loginFormAtom);
    /** @type function */
    const resetLoginStatus = useResetRecoilState(loginStatusAtom);

    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    /**
     * Handle an error from an unsuccessful API call.
     * @param {AxiosResponse|AxiosError} axiosError - The error object returned by axios
     * @return {void}
     */
    function handleFailure(axiosError) {
        try {
            const data = axiosError?.response?.data || axiosError?.data;
            setLoginStatusAfterFailedLogin(data, setLoginStatus);
            sessionStorage.removeItem('session');
            resetUser();
        } catch (error) {
            console.log({'error': error, 'axiosError': axiosError});
            const errorMessage = axiosError?.response?.data?.message || axiosError?.data?.message || axiosError;
            logout();                   // Reset the app state back to default so the user can try again
            showBoundary(errorMessage);  //Display the nearest error boundary
        }
    }

    /**
     * After a successful API call, update the Recoil state values for the app
     * @param {AxiosResponse|AxiosError} axiosResponse - The http response object from axios
     * @return {void}
     */
    function handleSuccess(axiosResponse) {
        try {
            setStateAfterSuccessfulLogin(
                axiosResponse.data,
                setAccountId,
                setBatchId,
                setLoginStatus,
                setProjectId,
                setUser,
                resetLoginForm
            );
        }  catch (error) {
            console.log(error);
            handleFailure(axiosResponse);
        }
    }

    /**
     * Post a new login to the API.
     * @param {Event} [e] - A form submission event
     * @return {Promise} - Returns the axios promise
     */
    function login(e) {
        if (e) e.preventDefault();
        setLoginStatus((prev) => (
            /** @type LoginStatus */
            {
                ...prev,
                currentStatusCode: 'PL',
                previousStatusCodes: appendToPreviousStatusCodes(prev.previousStatusCodes, prev.currentStatusCode)
            }
        ));
        const query = loginPostQuery(loginForm);
        query.then(handleSuccess).catch(handleFailure);
        return query;
    }

    /**
     * Log out, clearing App state.
     * @param {Event} [e] - A click event on a logout link
     * @return {void}
     */
    function logout(e) {
        if (e) e.preventDefault();

        sessionStorage.removeItem('session');
        resetUser({});
        resetLoginForm({});
        resetLoginStatus({});
    }

    return { login, logout }
}

export default useLoginActions;