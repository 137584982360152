import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { accountIdAtom, batchLoginCodesAtom, projectIdAtom } from "_atoms";
import { batchSelector } from "_selectors";

/**
 * Add an isSaved=true property to a login code object
 * @param {object} loginCode - The login code object for which to add the isSaved status
 * @return LoginCode
 */
function addIsSavedPropertyToLoginCode(loginCode) {
    return /** @type LoginCode */ {...loginCode, isSaved: true}
}

/**
* When the batch selector changes, make sure that the project id atom matches the project for the batch
*/
const useBatchSelector = () => {
    /** @type Batch|T */
    const batch= useRecoilValue(batchSelector);
    /** @type Dispatch<SetStateAction<string>> */
    const setAccountId= useSetRecoilState(accountIdAtom);
    /** @type Dispatch<SetStateAction<string>> */
    const setProjectId= useSetRecoilState(projectIdAtom);
    /** @type Dispatch<SetStateAction<LoginCode[]>> */
    const setBatchLoginCodes= useSetRecoilState(batchLoginCodesAtom);

    useEffect(() => {
        if (!!batch?.project?.id) {
            /** @type LoginCode[] */
            const loginCodesWithIsSavedProperty = batch?.loginCodes?.map(addIsSavedPropertyToLoginCode);
            setAccountId(batch?.project?.account);
            setBatchLoginCodes(loginCodesWithIsSavedProperty)
            setProjectId(batch?.project?.id);
        }
    }, [batch, setAccountId, setBatchLoginCodes, setProjectId]);
}

export { useBatchSelector };