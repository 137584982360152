import { atom } from "recoil";

/**
 * The id of the currently active account. A UUID.
 */
const accountIdAtom = atom({
  key: 'accountId',
  default: null
});

export { accountIdAtom };