import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import "./EmailAndPasswordForm.css";

const EmailAndPasswordForm = ({ alert, changeHandler, submitHandler }) => {

    return (
        <Container className="login-container fade-in">
            <Row className="justify-content-center">
                <Col md={6} lg={5}>
                    <Form onSubmit={submitHandler} className="email-and-password-form d-grid">
                        <FloatingLabel controlId="emailAddress" label="Email Address">
                            <Form.Control
                                autoComplete="username"
                                inputMode="email"
                                maxLength="320"
                                name="emailAddress"
                                size="lg"
                                type="email"
                                onChange={changeHandler}
                                placeholder="Email address"
                                required={true}
                            />
                        </FloatingLabel>
                        <FloatingLabel controlId="password" label="Password">
                            <Form.Control
                                autoComplete="current-password"
                                inputMode="password"
                                maxLength="255"
                                name="password"
                                size="lg"
                                type="password"
                                onChange={changeHandler}
                                placeholder="Password"
                                required={true}
                            />
                        </FloatingLabel>
                        <Button variant="primary" size="lg" type="submit">
                            Log in
                        </Button>
                        {alert}
                        <Link to="password-recovery" className="text-center mt-5 text-decoration-none">
                            I forget my password
                        </Link>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

EmailAndPasswordForm.propTypes = {
    alert: PropTypes.element,
    changeHandler: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired
};

export default EmailAndPasswordForm;