import { selector } from "recoil";
import {
    accountIdAtom,
    batchIdAtom,
    respondentFilterParamsAtom,
    projectIdAtom,
    respondentsLimitAtom,
    respondentsOffsetAtom,
    respondentSearchParamsAtom,
    respondentSortParamsAtom,
    showArchivedRespondentsAtom
} from "_atoms";
import { createRespondentQueryParams, createSortQueryString } from "_helpers/respondents-query-helpers"

/**
 * Convert the  current list of filters into
 */
const respondentsQueryParamsSelector = selector({
    key: 'respondentsQueryParams',
    get: ({get}) => {
        /** @type string */
        const accountId = get(accountIdAtom);
        /** @type string */
        const batchId = get(batchIdAtom);
        /** @type object */
        const isArchivedVisible = get(showArchivedRespondentsAtom);
        /** @type string */
        const projectId = get(projectIdAtom);
        /** @type object */
        const respondentsLimit = get(respondentsLimitAtom);
        /** @type object */
        const respondentsOffset = get(respondentsOffsetAtom);
        /** @type RespondentFilter[] */
        const respondentFilterParams = get(respondentFilterParamsAtom);
        /** @type RespondentSearchParams */
        const respondentSearchParams = get(respondentSearchParamsAtom);
        /** @type RespondentSortRule[] */
        const respondentSortParams = get(respondentSortParamsAtom);


        if (!batchId) {
            return null;
        } else if (!!respondentSearchParams.value) {
            /** @type 'account'|'project'|'batch' */
            const target = respondentSearchParams.target;
            /** @type string */
            const targetParam = target === 'account' ? 'batchProjectAccount' : (target === 'project' ? 'batchProject' : 'batch');
            /** @type string */
            const targetId = target === 'account' ? accountId : (target === 'project' ? projectId : batchId);
            /** @type object */
            const paramsWithoutSort = {
                limit: respondentsLimit,
                offset: respondentsOffset,
                [targetParam]: targetId,
                search: respondentSearchParams.value
            }
            /** @type string */
            const sortQueryString = createSortQueryString(respondentSortParams);
            /** @type object */
            const paramsWithSort = {
                ...paramsWithoutSort,
                sort: sortQueryString
            }
            return sortQueryString ? paramsWithSort : paramsWithoutSort;
        }

        return createRespondentQueryParams(
            batchId,
            isArchivedVisible.value,
            respondentFilterParams,
            respondentSearchParams,
            respondentSortParams
        )
    }
});

export { respondentsQueryParamsSelector };