import { atom } from 'recoil';

/**
 * Value of fields in the respondent search modal.
 */
const respondentSearchModalValuesAtom = atom({
    key: 'respondentSearchModalValues',
    default:
    /** @type RespondentSearchParams */
    {
        target: 'account',
        value: ''
    }
});

export { respondentSearchModalValuesAtom };