import React from "react";
import PropTypes from "prop-types";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./LoginInputField.css";

const LoginInputField = ({ alert, autoComplete, buttonLabel, changeHandler, className, description, inputMode,
                             instructions, label, maxLength, name, pattern, submitHandler, type, value}) => {

    return (
        <Container className="login-container fade-in">
            <Row className="justify-content-center">
                <Col md={6} lg={5}>
                    <Form onSubmit={submitHandler} className="login-form d-grid">
                        <ReactMarkdown remarkPlugins={[remarkGfm]} className="login-instructions">
                            {instructions}
                        </ReactMarkdown>
                        <FloatingLabel controlId={name} label={label}>
                            <Form.Control
                                autoComplete={autoComplete}
                                className={`login-input-field ${className}`}
                                inputMode={inputMode}
                                maxLength={maxLength}
                                name={name}
                                pattern={pattern}
                                size="lg"
                                type={type}
                                onChange={changeHandler}
                                placeholder={label}
                                value={value || ""}
                            />
                        </FloatingLabel>
                        <Button variant="primary" size="lg" className="login-submit-button" type="submit">
                            {buttonLabel}
                        </Button>
                        {alert}
                    </Form>
                </Col>
            </Row>
            <ReactMarkdown remarkPlugins={[remarkGfm]} className="login-description text-center">
                {description}
            </ReactMarkdown>
        </Container>
    );
}

LoginInputField.propTypes = {
    alert: PropTypes.element,
    autoComplete: PropTypes.string,
    buttonLabel: PropTypes.string.isRequired,
    changeHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    description: PropTypes.string,
    inputMode: PropTypes.string.isRequired,
    instructions: PropTypes.string,
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    pattern: PropTypes.string,
    submitHandler: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default LoginInputField;