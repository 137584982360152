import React from "react";
import { Link } from "react-router-dom";
import { ArchiveFill, GearFill, CreditCard, QuestionCircleFill } from "react-bootstrap-icons";
import './SidebarBottomNav.css';

const SidebarBottomNav = () => {
    return (
        <div className="sidebar-bottom-nav">
            <Link to="/help"><ArchiveFill size={18} /> View archived batches</Link>
            <Link to="/settings"><GearFill size={18} /> Settings</Link>
            <Link to="/billing"><CreditCard size={18} /> Billing</Link>
            <Link to="/help"><QuestionCircleFill size={18} /> Help</Link>
        </div>
    );
}

export default SidebarBottomNav;
