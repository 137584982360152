import PropTypes from "prop-types";
import React from "react";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import './SidebarDropdown.css';

const SidebarDropdown = ({activeId, choices, changeHandler, className, includeBlank, label}) => {
    /** @type string */
    const selectedId = activeId || '';

    /** @type ReactNode */
    const firstOption = !includeBlank ? '' : <option value="">Select</option>;

    /** @type ReactNode[] */
    const options = choices.map((choice, index) => {
        if (choice?.isArchived) return '';
        return <option key={index} value={choice?.id}>{choice?.name}</option>
    });

    return (
        <FloatingLabel className={`sidebar-dropdown ${className}`} label={label}>
            <Form.Select value={selectedId} onChange={changeHandler}>
                {firstOption}
                {options}
            </Form.Select>
        </FloatingLabel>
    )
}

SidebarDropdown.propTypes = {
    activeId: PropTypes.string,
    choices: PropTypes.arrayOf(PropTypes.object).isRequired,
    changeHandler: PropTypes.func.isRequired,
    label: PropTypes.string
};

export default SidebarDropdown;
