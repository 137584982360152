import { selector } from "recoil";
import { respondentIdAtom } from "_atoms";
import { respondentGetQuery } from "_queries/respondent-queries";

/**
 * The respondent for which to show results. A respondent object.
 */
const respondentSelector = selector({
    key: 'respondent',
    get: async ({get}) => {
        /** @type string */
        const respondentId = get(respondentIdAtom);
        /** @type AbortController */
        const controller = new AbortController();

        if (!respondentId) return null;

        /** @type Promise */
        return respondentGetQuery(respondentId, controller).then((axiosResponse) => {
            return axiosResponse.data;
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

export { respondentSelector };