import { useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useRecoilState } from 'recoil';
import { batchLoginCodesAtom } from "_atoms";
import {loginCodesPostQuery} from "_queries/login-code-queries";
import { updateLoginCodesAfterSave } from "_helpers/use-batch-login-code-helpers";

/**
* When the dom router path contains a batch id, set the batchId atom to match the page content
*/
const useBatchLoginCodes = () => {
    /** @type [LoginCode[], Dispatch<SetStateAction<string>>] */
    const [batchLoginCodes, setBatchLoginCodes]= useRecoilState(batchLoginCodesAtom);
    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        /** @type {LoginCode[]} - Get a list of the batch login codes that haven't yet been saved to the API */
        const unsavedLoginCodes = batchLoginCodes.filter((loginCode) => !loginCode?.isSaved);
        /** @type {AbortController} - Use an abort controller to cancel API requests if the component unmounts */
        const controller = new AbortController();

        /**
        * Handle the results of a failed request from the API.
        * @param {AxiosError|AxiosResponse} axiosError - The axios error object returned after the API call
        * @return {void}
        */
        function handleFailure(axiosError) {
            const errorMessage = axiosError?.response?.data?.message || axiosError?.data?.message || axiosError;
            showBoundary(errorMessage);
        }

        /**
        * Handle the results of a successful request from the API.
        * @param {AxiosResponse} axiosResponse - The axios response object returned after the API call
        * @return {void}
        */
        function handleSuccess(axiosResponse) {
            setBatchLoginCodes((prev) =>
                /** @type LoginCode[] */
                updateLoginCodesAfterSave(prev, axiosResponse.data.results)
            );
        }

        if (!!unsavedLoginCodes?.length) {
            loginCodesPostQuery(unsavedLoginCodes, controller).then(handleSuccess).catch(handleFailure);
        }
    }, [batchLoginCodes, setBatchLoginCodes, showBoundary]);
}

export { useBatchLoginCodes };