import { atom } from "recoil";

/**
 * Boolean of whether the sidebar is currently expanded
 */
const isSidebarExpandedAtom = atom({
  key: 'isSidebarExpanded',
  default: true
});

export { isSidebarExpandedAtom };