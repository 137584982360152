import PropTypes from "prop-types";
import React from "react";
import BatchList from "Sidebar/components/BatchList/BatchList";
import './ExpandedProject.css';

const ExpandedProject = ({ activeBatchId, project, batchClickHandler, projectClickHandler }) => {
    const activeClassName = !activeBatchId ? 'active' : '';

    return (
        <div className="project expanded chevron bottom">
            <a
                href={`/projects/${project?.id}`}
                className={`project-name ${activeClassName}`}
                onClick={projectClickHandler}
                data-project={project?.id}
            >
                {project?.name}
            </a>
            <BatchList
                activeBatchId={activeBatchId}
                project={project}
                batchClickHandler={batchClickHandler}
            />
        </div>
    )
}

ExpandedProject.propTypes = {
    activeBatchId: PropTypes.string,
    project: PropTypes.object.isRequired,
    batchClickHandler: PropTypes.func.isRequired,
    projectClickHandler: PropTypes.func.isRequired,
};

export default ExpandedProject;
