import { atom } from 'recoil';

/**
 * The list of login codes associated with the currently active batch.
 */
const batchLoginCodesAtom = atom({
    key: 'batchLoginCodes',
    default:
    /** @type LoginCode[] */
    [{
        id: '',
        batch: '',
        createdAt: '',
        customIdField: null,
        expiresAt: '',
        languages: [],
        isSaved: true,
        project: null,
        respondent: null,
        value: ''
    }]
});

export { batchLoginCodesAtom };