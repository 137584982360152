import { useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { batchIdAtom } from "_atoms";

/**
* When the dom router path contains a batch id, set the batchId atom to match the page content
*/
const useBatchParam = () => {
    /** @type Dispatch<SetStateAction<string>> */
    const setBatchId= useSetRecoilState(batchIdAtom);
    /** @type ?string */
    const { batchId } = useParams();

    useEffect(() => {
        if (!!batchId) {
            setBatchId(batchId);
        }
    }, [batchId, setBatchId]);
}

export { useBatchParam };