import { useEffect } from "react";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import { useErrorBoundary } from "react-error-boundary";
import { accountIdAtom, batchIdAtom, loginFormAtom, loginStatusAtom, projectIdAtom, userAtom } from '_atoms';
import {
    appendToPreviousStatusCodes,
    setStateAfterSuccessfulLogin,
    setLoginStatusAfterFailedLogin
} from "_helpers/login-helpers";
import { loginGetQuery } from "_queries/login-queries";

/**
 * Upon first loading the app, check if login info is stored in the session or url params. When info is stored in the
 * session, reload the app state from the API. When url params have a login code value, post a new login.
 */
const useSessionVarsToLogin = () => {
    /** @type Dispatch<SetStateAction<string>> */
    const setAccountId = useSetRecoilState(accountIdAtom);
    /** @type Dispatch<SetStateAction<string>> */
    const setBatchId = useSetRecoilState(batchIdAtom);
    /** @type Dispatch<SetStateAction<LoginForm>> */
    const setLoginForm = useSetRecoilState(loginFormAtom);
    /** @type Dispatch<SetStateAction<LoginStatus>> */
    const setLoginStatus = useSetRecoilState(loginStatusAtom);
    /** @type Dispatch<SetStateAction<string>> */
    const setProjectId = useSetRecoilState(projectIdAtom);
    /** @type Dispatch<SetStateAction<User>> */
    const setUser = useSetRecoilState(userAtom);
    /** @type Dispatch<SetStateAction<>> */
    const resetLoginForm = useResetRecoilState(loginFormAtom);
    /** @type Dispatch<SetStateAction<>> */
    const resetLoginStatus = useResetRecoilState(loginStatusAtom);
    /** @type function */
    const resetUser = useResetRecoilState(userAtom);

    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    /**
     * Upon first loading the app, check if login info is stored in the session or url params. When info is stored in the
     * session, reload the app state from the API. When url params have a login code value, post a new login.
     */
    useEffect(() => {
        /** @type {AbortController} - Use an abort controller to cancel API requests if the component unmounts */
        const controller = new AbortController();
        /** @type {Session} - The current session information */
        const session = JSON.parse(sessionStorage.getItem('session'));

        /**
        * Handle the results of a failed request from the API.
        * @param {AxiosError|AxiosResponse} axiosError - The axios error object returned after the API call
        * @return {void}
        */
        function handleFailure(axiosError) {
            try {
                const data = axiosError?.response?.data || axiosError?.data;
                setLoginStatusAfterFailedLogin(data, setLoginStatus);
            } catch(error) {
                console.log({'error': error, 'axiosError': axiosError});
                sessionStorage.removeItem('session');
                resetUser({});

                //Display the nearest error boundary
                const errorMessage = axiosError?.response?.data?.message || axiosError?.data?.message || axiosError;
                showBoundary(errorMessage);
            }
        }

        /**
        * Handle the results of a successful request from the API.
        * @param {AxiosResponse} axiosResponse - The axios response object returned after the API call
        * @return {void}
        */
        function handleSuccess(axiosResponse) {
            try {
                setStateAfterSuccessfulLogin(
                    axiosResponse.data,
                    setAccountId,
                    setBatchId,
                    setLoginStatus,
                    setProjectId,
                    setUser,
                    resetLoginForm
                );
            } catch(error) {
                handleFailure(axiosResponse);
            }
        }

        if (!!session?.id) {
            // When login info is present in the session, use it to retrieve the information for the given login id
            setLoginStatus((prev) => (
                /** @type LoginStatus */
                {
                    ...prev,
                    currentStatusCode: 'PL',
                    previousStatusCodes: appendToPreviousStatusCodes(prev.previousStatusCodes, prev.currentStatusCode)
                }
            ));
            loginGetQuery(session?.id, controller).then(handleSuccess).catch(handleFailure);
        }

        // When the effect unmounts, abort any active API requests
        return () => {
            controller.abort();
        };
    }, [resetLoginForm, resetLoginStatus, resetUser, setAccountId, setBatchId, setLoginForm, setLoginStatus,
        setProjectId, setUser, showBoundary]);
}

export { useSessionVarsToLogin };