import { atom } from 'recoil';

/**
 * List of respondent filters values currently selected in the filters modal.
 */
const respondentFilterModalValuesAtom = atom({
    key: 'respondentFilterModalValues',
    default: /** RespondentFilter[] */ []
});

export { respondentFilterModalValuesAtom };