import PropTypes from "prop-types";
import React from "react";
import './BatchList.css';

const BatchList = ({ activeBatchId, project, batchClickHandler }) => {
    /** @type Batch[] */
    const batches = project?.batches || [];

    /** @type ReactNode[] */
    const batchListElements = batches.map((batch, index) => {
        const className = batch.id === activeBatchId ? 'active' : '';
        return (
            <li key={index}>
                <a
                    className={`batch-name ${className}`}
                    href={`/batch/${batch.id}`}
                    onClick={batchClickHandler}
                    data-batch={batch.id}
                >
                    {batch.name}
                </a>
            </li>
        )
    });

    return (
        <ul className="batch-list">
            {batchListElements}
        </ul>
    )
}

BatchList.propTypes = {
    activeBatchId: PropTypes.string,
    project: PropTypes.object.isRequired,
    batchClickHandler: PropTypes.func.isRequired
};

export default BatchList;
