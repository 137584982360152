import { useEffect } from "react";
import { useResetRecoilState } from 'recoil';
import { respondentSearchParamsAtom } from "_atoms";
import { useNavigate } from "react-router-dom";

/**
 * Reset the respondent search parameters upon navigation to a new page.
 */
const useNavigation = () => {
    /** @type function */
    const resetRespondentSearchParams = useResetRecoilState(respondentSearchParamsAtom);

    const navigate = useNavigate();

    useEffect(() => {
        resetRespondentSearchParams();
    }, [resetRespondentSearchParams, navigate]);
}

export { useNavigation };