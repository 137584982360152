import { api } from "./query-interceptors";

/**
 * Retrieve the login codes for a given batch
 * @param {string} batchId - The id of the projects to retrieve from the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
/*
export async function loginCodesGetQuery(batchId, controller) {
    const requestOptions = {
        method: 'GET',
        url: `/login-codes/`,
        params: {
            batch: batchId
        },
        signal: controller.signal
    };
    return api(requestOptions);
}
*/

/**
 * Post a list of new login code objects to the API
 * @param {LoginCode[]} loginCodes - List of login code objects to post to the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function loginCodesPostQuery(loginCodes, controller) {
    const requestOptions = {
        method: 'POST',
        url: `/login-codes/`,
        data: loginCodes,
        signal: controller.signal
    };
    return api(requestOptions);
}