import { atom } from 'recoil';

/**
 * List of sorting rules currently displayed in the respondent sort modal.
 */
const respondentSortModalValuesAtom = atom({
    key: 'respondentSortModalValues',
    default: /** @type RespondentSortRule[]*/ []
});

export { respondentSortModalValuesAtom };