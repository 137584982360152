import { atom } from 'recoil';

/**
 * List of respondent sorting rules currently used to order respondent objects returned from the API.
 */
const respondentSortParamsAtom = atom({
    key: 'respondentSortParams',
    default: /** @type RespondentSortRule[]*/ []
});

export { respondentSortParamsAtom };