import PropTypes from "prop-types";
import React from "react";

const CollapsedProject = ({ id, name, projectClickHandler }) => {
    return (
        <div className="project collapsed chevron right">
            <a href={`/projects/${id}`} className="project-name" onClick={projectClickHandler} data-project={id}>
                { name }
            </a>
        </div>
    )
}

CollapsedProject.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

export default CollapsedProject;
