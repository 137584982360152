import React from "react";
import { useRecoilValue } from 'recoil';
import Sidebar from 'Sidebar/Sidebar';
import { isSidebarExpandedAtom } from '_atoms';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import './Home.css';

const Home = () => {
    /** @type boolean */
    const isSidebarExpanded = useRecoilValue(isSidebarExpandedAtom);
    /** @type string */
    const mainClassName = isSidebarExpanded ? 'sidebar-expanded' : '';


    return (
        <>
            <Sidebar />
            <div className={`home main ${mainClassName}`}>
                <Breadcrumb>
                    <Breadcrumb.Item active>Home</Breadcrumb.Item>
                </Breadcrumb>

                Home page content
            </div>
        </>
    );
}

export default Home;
