import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { RecoilRoot } from "recoil";
import { useSessionVarsToLogin, useNavigation } from "_effects";
import Home from 'Home/Home';
import PageLoadingSpinner from "_components/PageLoadingSpinner/PageLoadingSpinner";
import Login from "Login/Login";
import "./typedefs";
import "bootstrap/dist/css/bootstrap.min.css";

// Lazy load everything after the login page to optimize app performance, loading components only when they are required
const AddRespondents = React.lazy(() => import('AddRespondents/AddRespondents'));
const Archived = React.lazy(() => import('Archived/Archived'));
const Batch = React.lazy(() => import('Batch/Batch'));
const BatchEdit = React.lazy(() => import('BatchEdit/BatchEdit'));
const Billing = React.lazy(() => import('Billing/Billing'));
const DefaultFallback = React.lazy(() => import('_boundaries/DefaultFallback/DefaultFallback'));
const Help = React.lazy(() => import('Help/Help'));
const ListenersFallback = React.lazy(() => import('_boundaries/ListenersFallback/ListenersFallback'));
const PasswordReset = React.lazy(() => import('PasswordReset/PasswordReset'));
const Project = React.lazy(() => import('Project/Project'));
const Respondent = React.lazy(() => import('Respondent/Respondent'));
const SetPassword = React.lazy(() => import('SetPassword/SetPassword'));
const Settings = React.lazy(() => import('Settings/Settings'));

const Listeners = () => {
    useSessionVarsToLogin();     // Upon first app render, check if there are session variables we can use to log in again.
    useNavigation();             // Whenever the url changes, reset the respondent search parameters.
}

const App = () => {
    return (
        <React.Suspense fallback={<PageLoadingSpinner/>}>
            <RecoilRoot>
                <BrowserRouter>
                    <ErrorBoundary FallbackComponent={DefaultFallback}>
                        <React.Suspense fallback={<PageLoadingSpinner/>}>
                            <ErrorBoundary FallbackComponent={ListenersFallback}>
                                <Listeners/>
                            </ErrorBoundary>
                            <Routes>
                                <Route path='/' element={<Login />} />
                                <Route path='login' element={<Login />} />
                                <Route path='home' element={<Home />} />
                                <Route path='archived' element={<Archived />} />
                                <Route path='batches/:batchId' element={<Batch />} />
                                <Route path='batches/:batchId/edit' element={<BatchEdit />} />
                                <Route path='batches/:batchId/add-respondents' element={<AddRespondents />} />
                                <Route path='billing' element={<Billing />} />
                                <Route path='help' element={<Help />} />
                                <Route path='password-reset' element={<PasswordReset />} />
                                <Route path='projects/:projectId' element={<Project />} />
                                <Route path='respondents/:respondentId' element={<Respondent />} />
                                <Route path='set-password/:token' element={<SetPassword />} />
                                <Route path='settings' element={<Settings />} />
                            </Routes>
                        </React.Suspense>
                    </ErrorBoundary>
                </BrowserRouter>
            </RecoilRoot>
        </React.Suspense>
    );
}

export default App;