import { atom } from "recoil";

/**
 * The id of the currently active batch. A UUID.
 */
const batchIdAtom = atom({
  key: 'batchId',
  default: null
});

export { batchIdAtom };