import { atom } from 'recoil';

/**
 * Respondent search parameters defining what value to look for and what target to look within.
 */
const respondentSearchParamsAtom = atom({
    key: 'respondentSearchParams',
    default:
    /** @type RespondentSearchParams */
    {
        target: 'account',
        value: ''
    }
});

export { respondentSearchParamsAtom };