import { atom } from "recoil";

/**
 * The currently selected method for sending invites for respondents to complete materials. One of bulkLink,
 * enterEmails, or uploadCsv.
 */
const invitationMethodAtom = atom({
  key: 'invitationMethod',
  default: 'bulkLink'
});

export { invitationMethodAtom };