import { atom } from "recoil";

/**
 * The limit parameters to use when retrieving the list of respondents from the API. The limit determines the
 * maximum number of respondents to retrieve from the API.
 */
const respondentsLimitAtom = atom({
    key: 'respondentsLimit',
    default: 100
});

export { respondentsLimitAtom };