import { atom } from "recoil";

/**
 * The id of the project currently expanded in the sidebar. A UUID.
 */
const projectIdAtom = atom({
  key: 'projectId',
  default: null
});

export { projectIdAtom };