import { atom } from 'recoil';

/**
 * List of export options to use when exporting data respondents. Returns a list of ExportOption objects.
 */
const exportOptionsAtom = atom({
    key: 'exportOption',
    default:
    /** @type ExportOptions */
    {
        includeArchived: false,
        includeEmailAddress: false,
        includeName: true,
        includeResponses: false,
        includeScores: true,
        source: 'respondent',
    }
});

export { exportOptionsAtom };