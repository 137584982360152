import React from "react";
import Container from 'react-bootstrap/Container'
import HashLoader from "react-spinners/HashLoader";
import './PageLoadingSpinner.css';

const PageLoadingSpinner = ({ label }) => {
    return (
        <Container className="text-center">
            <div className="page-loading-div">
                <HashLoader
                    color='#007bff'
                    loading={true}
                    size={150}
                />
                <div className="page-loading-label">
                    {label}
                </div>
            </div>
        </Container>
    )
}

export default PageLoadingSpinner;