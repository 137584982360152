import { atom } from "recoil";

/**
 * Object defining whether to fetch archived/deleted respondents from the API when displaying respondents in a batch.
 * For some reason, changes to the atom value between true vs. false aren't triggering selector updates. To force
 * selector updates, added a count to ensure that the selector recognizes that the atom has changed.
 */
const showArchivedRespondentsAtom = atom({
  key: 'showArchivedRespondents',
  default:
  /** @type ArchivedRespondentVisibility */
  {
    count: 0,
    value: false
  }
});

export { showArchivedRespondentsAtom };