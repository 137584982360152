import { atom } from "recoil";

/**
 * Which modal (if any) to show
 */
const whatToArchiveAtom = atom({
  key: 'whatToArchive',
  default: 'respondent'
});

export { whatToArchiveAtom };