import React from "react";
import PropTypes from "prop-types";
import Logo from "_components/Logo/Logo";
import './Background.css';

const Background = ({ content }) => {
    return (
        <div className="fullscreen-background">
            <Logo />
            {content}
        </div>
    );
}

Background.propTypes = {
    content: PropTypes.object.isRequired
};

export default Background;