import { api } from "./query-interceptors";

/**
 * Retrieve information for a given project from the API
 * @param {string} projectId - The id of the project to retrieve from the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function projectGetQuery(projectId, controller) {
    const expandString = "batches";
    const requestOptions = {
        method: 'GET',
        url: `/projects/${projectId}/`,
        params: {
            expand: expandString
        },
        signal: controller.signal
    };
    return api(requestOptions);
}