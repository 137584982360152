import { selector } from "recoil";
import { projectIdAtom, userAtom } from "_atoms";
import { projectGetQuery } from "_queries/project-queries";

/** When there is an active project, fetch the corresponding project information from the API. */
const projectSelector = selector({
    key: 'project',
    get: async ({get}) => {
        /** @type string */
        const projectId = get(projectIdAtom);
        /** @type {User} - Check that there is a valid user atom to verify that the user is currently logged in */
        const user = get(userAtom);
        /** @type AbortController */
        const controller = new AbortController();

        if (!projectId || !user?.id) return null;

        /** @type Promise */
        return projectGetQuery(projectId, controller).then((axiosResponse) => {
            return axiosResponse.data;
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

export { projectSelector };