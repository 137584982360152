import React from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Logo from "_components/Logo/Logo";
import ProjectList from "./containers/ProjectList/ProjectList";
import SidebarToggler from "./components/SidebarToggler/SidebarToggler";
import SidebarDropdown from "./components/SidebarDropdown/SidebarDropdown";
import SidebarBottomNav from "./components/SidebarBottomNav/SidebarBottomNav";
import { accountIdAtom, isSidebarExpandedAtom, userAtom } from '_atoms';
import './Sidebar.css';

const Sidebar = () => {
    /** @type string */
    const activeAccountId = useRecoilValue(accountIdAtom);
    /** @type User */
    const user = useRecoilValue(userAtom);
    /** @type [boolean, Dispatch<SetStateAction<boolean>>] */
    const [isSidebarExpanded, setIsSidebarExpanded] = useRecoilState(isSidebarExpandedAtom);
    /** @type string */
    const sidebarContainerClassName = isSidebarExpanded ? 'expanded' : 'collapsed';
    /** @type Account[] */
    const accounts = user?.accountUserRelations?.map((accountUserRelation) => accountUserRelation?.account);
    /** @type Dispatch<SetStateAction<boolean>> */
    const setAccountId = useSetRecoilState(accountIdAtom);

    const toggleSidebar = () => {
        setIsSidebarExpanded((prev) => !prev)
    }

    const accountChangeHandler = (e) => {
        setAccountId(e.target.value);
    }

    const accountDropdown = accounts.length <= 1 ? '' : <SidebarDropdown
        activeId={activeAccountId}
        choices={accounts}
        changeHandler={accountChangeHandler}
        className="account-dropdown"
        includeBlank={false}
        label="Account"
    />

    return (
        <div className={`sidebar-container ${sidebarContainerClassName}`}>
            <div className='sidebar'>
                <Logo />
                <SidebarToggler toggleSidebar={toggleSidebar} />
                {accountDropdown}
                <ProjectList />
                <SidebarBottomNav />
            </div>
        </div>
    );
}

export default Sidebar;
