import { api } from "./query-interceptors";

/**
 * Retrieve information for a given batch from the API
 * @param {string} batchId - The id of the batch to retrieve from the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function batchGetQuery(batchId, controller) {
    const expandString = "carelessResponseChecks,debrief,loginCodes,materials,project,summaryScales.bandSet,welcome";
    const requestOptions = {
        method: 'GET',
        url: `/batches/${batchId}/`,
        params: {
            expand: expandString
        },
        signal: controller.signal
    };
    return api(requestOptions);
}