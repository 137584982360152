import { atom } from "recoil";

/**
 * A user object containing their id, name, avatar, account user relations, and email address.
 */
const userAtom = atom({
  key: 'user',
  default:
  /** @type User */
  {
    id: '',
    accountUserRelations:[],
    avatar: null,
    createdAt: null,
    customId: '',
    emailAddress: '',
    issuer: '',
    firstName: null,
    isActive: null,
    isStaff: null,
    lastName: '',
    respondents: [],
    url: ''
  },
});

export { userAtom };