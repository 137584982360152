import { selector } from "recoil";
import { isSidebarExpandedAtom } from "_atoms";

/**
 * The class name for the main content div depends on whether the sidebar is expanded or collapsed.
 */
const mainDivClassNameSelector = selector({
    key: 'mainDivClassName',
    get: ({get}) => {
        /** @type string */
        const isSidebarExpanded = get(isSidebarExpandedAtom);

        return isSidebarExpanded ? 'sidebar-expanded' : '';
    }
});

export { mainDivClassNameSelector };