import { atom } from 'recoil';

/**
 * A LoginStatus object recording (1) the login status code (a 2-character string) for the most recent attempt,
 * (2) a list of the unique previous status codes, (3) an error message to show on screen, and (4) optionally a
 * customIdField to use for soliciting a 3rd-party custom identifier (e.g., MTurk Worker ID, Participant ID,
 * student ID).
 */
const loginStatusAtom = atom({
    key: 'loginStatus',
    default:
    /** @type LoginStatus */
    {
        currentStatusCode: 'MP',
        customIdField: '',
        errorMessage: '',
        previousStatusCodes: []
    }
});

export { loginStatusAtom };