import { atom } from "recoil";

/**
 * The offset parameter to use when retrieving the list of respondents from the API. The offset determines the number
 * of respondents to skip.
 */
const respondentsOffsetAtom = atom({
    key: 'respondentsOffset',
    default: 0
});

export { respondentsOffsetAtom };