/**
 * Create a query string for the sort parameter to order respondent objects returned by the API.
 * @param {RespondentSortRule[]} respondentSortParams - The id of the invitee to update
 * @return {string}
 */
export function createSortQueryString(respondentSortParams) {
    const queryStrings = respondentSortParams.map(convertSortRuleToString)
    return queryStrings.join();
}

/**
 * Create a query string for the sort parameter to order respondent objects returned by the API.
 * @param {RespondentSortRule} respondentSortRule - The rule to convert to a string
 * @return {string}
 */
function convertSortRuleToString(respondentSortRule) {
    const directionString = respondentSortRule.direction === 'desc' ? '-' : '';
    return directionString + respondentSortRule.field;
}

/**
 * Create a key/value pair for a given respondent filter
 * @param {RespondentFilter} filter - The filter to convert to a key/value pair array
 * @return {Array<string, string|number>}
 */
function createKeyValuePair(filter) {
    return /** @type Array<string, string|number> */ [filter.field + filter.comparison, filter.value]
}

/**
 * Create a query string for the sort parameter to order respondent objects returned by the API.
 * @param {string} batchId - The uuid of the batch for which to retrieve respondents
 * @param {boolean} isArchivedVisible - Whether to show archived respondents
 * @param {RespondentFilter[]} respondentFilterParams - The rule to convert to a string
 * @param {RespondentSearchParams} respondentSearchParams - The rule to convert to a string
 * @param {RespondentSortRule[]} respondentSortParams - The rule to convert to a string
 * @return {object}
 */
export function createRespondentQueryParams(
    batchId,
    isArchivedVisible,
    respondentFilterParams,
    respondentSearchParams,
    respondentSortParams
) {
    /** @type {RespondentFilter[]} - Restrict the list of RespondentFilters to only the ones for this batch */
    const filterParamsForBatch = respondentFilterParams.filter((filter) => filter?.batch === batchId)
    /** @type {Array<Array<string, string|number>>} - Create a list of the filter key/value pairs */
    const filterKeyValuePairs = filterParamsForBatch.map(createKeyValuePair);
    const filterMap = new Map(filterKeyValuePairs);
    /** @type {object} - Convert the filterMap into an object */
    const filters = Object.fromEntries(filterMap);
    /** @type {object} - Add batch id to the filters */
    const filtersWithBatch = {...filters, batch: batchId}
    /** @type {object} - Add a deletedAtIsnull parameter when hiding archived respondents */
    const filtersWithArchived = isArchivedVisible ? filtersWithBatch : {...filtersWithBatch, deletedAtIsNull: true}
    /** @type {RespondentSortRule[]} - Restrict the list of RespondentSortRules to only the rules for this batch */
    const sortParamsForBatch = respondentSortParams.filter((sortParam) => sortParam?.batch === batchId)
    /** @type {string} - Create a query string to reflect the sort parameters */
    const sortQueryString = createSortQueryString(sortParamsForBatch);
    /** @type {object} - Add a sort parameter when a sort order is specified */
    return !sortQueryString ? filtersWithArchived : {...filtersWithArchived, sort: sortQueryString}
}