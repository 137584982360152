import { atom } from 'recoil';

/**
 * List of respondent filters currently active and used to filter the list of respondents from the API.
 */
const respondentFilterParamsAtom = atom({
    key: 'respondentFilterParams',
    default: /** RespondentFilter[] */ []
});

export { respondentFilterParamsAtom };