import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import CollapsedProject from "Sidebar/components/CollapsedProject/CollapsedProject";
import ExpandedProject from "Sidebar/components/ExpandedProject/ExpandedProject";
import SidebarDropdown from "Sidebar/components/SidebarDropdown/SidebarDropdown";
import { batchIdAtom, projectIdAtom } from '_atoms';
import { accountSelector, projectSelector } from '_selectors';
import './ProjectList.css';

const ProjectList = () => {
    /** @type AccountWithProjectsExpanded|T */
    const account = useRecoilValue(accountSelector);
    /** @type Dispatch<SetStateAction<string>> */
    const setProjectId = useSetRecoilState(projectIdAtom);
    /** @type [string, Dispatch<SetStateAction<string>>] */
    const [activeBatchId, setBatchId] = useRecoilState(batchIdAtom);
    /** @type *|T */
    const activeProject = useRecoilValue(projectSelector);
    /** @type Project[] */
    const projects = account?.projects || [];

    const navigate = useNavigate();

    const projectClickHandler = (e) => {
        e.preventDefault();
        setBatchId(null);
        navigate(`/projects/${e.target.dataset.project}`);
        setProjectId(e.target.dataset.project);
    }

    const projectChangeHandler = (e) => {
        setBatchId(null);
        setProjectId(e.target.value || null);
        const url = e.target.value ? `/projects/${e.target.value}` : '/home';
        navigate(url);
    }

    const batchChangeHandler = (e) => {
        setBatchId(e.target.value || null);
        const url = e.target.value ? `/batches/${e.target.value}` : `/projects/${activeProject?.id}`;
        navigate(url);
    }

    const batchClickHandler = (e) => {
        e.preventDefault();
        setBatchId(e.target.dataset.batch);
        navigate(`/batches/${e.target.dataset.batch}`);
    }

    const projectList = projects.map((project, index) => {
        if (project?.id === activeProject?.id) {
            return <ExpandedProject
                key={index}
                activeBatchId={activeBatchId}
                project={activeProject}
                batchClickHandler={batchClickHandler}
                projectClickHandler={projectClickHandler}
            />
        } else if (project?.isArchived) {
            return '';
        } else {
            return <CollapsedProject
                key={index}
                id={project?.id}
                name={project?.name}
                projectClickHandler={projectClickHandler}
            />
        }
    });

    const batchDropdown = !activeProject?.id ? '' : <SidebarDropdown
        activeId={activeBatchId}
        choices={activeProject?.batches}
        changeHandler={batchChangeHandler}
        className="batch-dropdown"
        includeBlank={true}
        label="Batch"
    />
    
    return (
        <>
            <SidebarDropdown
                activeId={activeProject?.id}
                choices={projects}
                changeHandler={projectChangeHandler}
                className="project-dropdown"
                includeBlank={true}
                label="Project"
            />
            {batchDropdown}
            <div className="project-list">
                {projectList}
            </div>
        </>

    );
}

export default ProjectList;
