import React from "react";
import { useRecoilState } from "recoil";
import { Navigate } from "react-router-dom";
import { loginFormAtom, loginStatusAtom } from "_atoms";
import PageLoadingSpinner from "_components/PageLoadingSpinner/PageLoadingSpinner";
import LoginAlert from "Login/containers/LoginAlert";
import LoginInputField from "Login/components/LoginInputField/LoginInputField";
import EmailAndPasswordForm from "Login/components/EmailAndPasswordForm/EmailAndPasswordForm"
import Background from "_components/Background/Background";
import useLoginActions from "_actions/login-actions";
import { checkForErrorMessage } from "_helpers/login-helpers";

/**
* Feature for rending login-related components prior to the respondent receiving a valid JWT authentication token
*/
const Login = () => {
    /** @type [LoginStatus, Dispatch<SetStateAction<LoginStatus>>] */
    const [loginStatus, setLoginStatus] = useRecoilState(loginStatusAtom);
    /** @type [LoginForm, Dispatch<SetStateAction<LoginForm>>] */
    const [loginForm, setLoginForm] = useRecoilState(loginFormAtom);
    /** @type boolean */
    const hasError = checkForErrorMessage(loginStatus);
    /** @type {string} - The field class name depends on whether an error message is being displayed */
    const errorClassName = hasError ? "is-invalid" : "";

    const loginActions = useLoginActions();

    /**
     * @type {function} - When the input field value changes, update the LoginForm atom and clear any error
     *                    messages being displayed
     */
    const handleChange = (e) => {
        setLoginForm((prev) => ({...prev, [e.target.name]:e.target.value}));
        setLoginStatus((prev) => ({...prev, errorMessage: ''}));
    }

    /** @type {JSX.Element} - Form for soliciting a login code */
    const emailAndPasswordForm = <EmailAndPasswordForm
        alert={<LoginAlert/>}
        changeHandler={handleChange}
        submitHandler={loginActions.login}
    />

    /** @type {JSX.Element} - Form for soliciting a respondent's one-time password when they have one. */
    const oneTimePasswordForm = <LoginInputField
        alert={<LoginAlert/>}
        autoComplete="one-time-code"
        buttonLabel="Verify code"
        changeHandler={handleChange}
        className={`login-one-time-password ${errorClassName}`}
        inputMode="numeric"
        instructions="Enter the 6-digit code from  your 2-factor authentication app"
        label="Authentication code"
        maxLength={6}
        name="oneTimePassword"
        pattern={"[0-9]{6}"}
        submitHandler={loginActions.login}
        type="text"
        value={loginForm?.oneTimePassword}
    />;

    /**
     * @type {JSX.Element} - When a user with two-factor authentication enabled lost their one-time password
     * device, they can use backup one-time-use recovery tokens to log in.
     */
    const backupOneTimePasswordForm = <LoginInputField
        alert={<LoginAlert/>}
        buttonLabel="Verify backup code"
        changeHandler={handleChange}
        className={errorClassName}
        inputMode="text"
        instructions="Enter one of the backup codes created when you set up 2-factor authentication."
        label="Backup authentication code"
        name="backupOneTimePassword"
        submitHandler={loginActions.login}
        type="password"
        value={loginForm?.backupOneTimePassword}
    />;

    // The status dictionary defines what form to show or what page to navigate to for each possible status code
    const statusDictionary = {
        // Waiting for the results of the assessment fetch query from the API
        'PL': <PageLoadingSpinner/>,
        // Authenticated with a login code
        'AC': emailAndPasswordForm,
        // Authenticated and provided a password
        'AP': <Navigate to="/home" replace={false}/>,
        // Authenticated with a valid password and 2-factor authentication
        'AV': <Navigate to="/home" replace={false}/>,
        // Invalid login code
        'IL': emailAndPasswordForm,
        // Expired login code
        'EL': emailAndPasswordForm,
        // Missing login code
        'ML': emailAndPasswordForm,
        // Invalid custom id
        'IC': emailAndPasswordForm,
        // Missing custom id
        'MC': emailAndPasswordForm,
        // Invalid password
        'IP': emailAndPasswordForm,
        // Missing password
        'MP': emailAndPasswordForm,
        // Invalid one-time password (for 2-factor verification)
        'IO': oneTimePasswordForm,
        // Missing one-time password (for 2-factor verification)
        'MO': oneTimePasswordForm,
        // Invalid backup static one-time password (for 2-factor verification)
        'IB': backupOneTimePasswordForm,
        // No 2-factor authentication set up for that user
        'NV': <p>Submitted an OTP token but user has not set up 2-factor verification.</p>,
        // No backup tokens set up for that user
        'NB': <p>Submitted a backup token but user has not set up backup tokens.</p>
    }

    // Verify that the loginStatus code returned by the API is one of the permitted ones
    if (!(loginStatus?.currentStatusCode in statusDictionary)) {
        console.log(loginStatus?.currentStatusCode + ' is not one of the valid status codes.');
        throw new Error("The login returned an invalid status code.");
    }

    return <Background content={statusDictionary[loginStatus.currentStatusCode]} />
}

export default Login;