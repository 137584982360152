import { api } from "./query-interceptors";

/**
 * Retrieve information for a given account from the API
 * @param {string} accountId - The id of the account to retrieve from the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function accountGetQuery(accountId, controller) {
    const expandString = "primaryContact,projects";
    const requestOptions = {
        method: 'GET',
        url: `/accounts/${accountId}/`,
        params: {
            expand: expandString
        },
        signal: controller.signal
    };
    return api(requestOptions);
}