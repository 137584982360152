import { atom } from 'recoil';

/**
 * Info a respondent submits to log in
 */
const loginFormAtom = atom({
    key: 'loginForm',
    default:
    /** @type LoginForm */
    {
        customIdValue: null,
        loginCodeValue: '',
        password: null,
        oneTimePassword: null,
        backupOneTimePassword: null,
    }
});

export { loginFormAtom };