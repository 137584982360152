import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
// import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);

// reportWebVitals(console.log);