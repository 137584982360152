/**
 * Append to the list of previous status codes, returning a list of the unique status codes already seen
 * @param {string[]} previousStatusCodes - The list of previous status codes
 * @param {string} newStatusCode - The new status code to append
 * @return {string[]}
 */
export function appendToPreviousStatusCodes(previousStatusCodes, newStatusCode) {
    return [...new Set([...previousStatusCodes, newStatusCode])];
}

/**
 * Check whether to display an error message on the login screen
 * @param {LoginStatus} loginStatus - The login status object
 * @return {boolean}
 */
export function checkForErrorMessage(loginStatus) {
    /** @type boolean */
    const isStatusRepeated = loginStatus?.previousStatusCodes?.includes(loginStatus?.currentStatusCode);
    /** @type boolean */
    const statusCodeStartsWithM = loginStatus?.currentStatusCode?.startsWith("M");
    /** @type boolean */
    return !!loginStatus?.errorMessage && (!statusCodeStartsWithM || isStatusRepeated);
}

/**
 * Set the state of the loginStatus atom after a failed login attempt.
 * @param {Login|T} data - The login object returned by the API in response to the failed login attempt
 * @param {Dispatch<SetStateAction<LoginStatus>>} setLoginStatus - Function to set the state of the login status atom
 * @return {void}
 */
export function setLoginStatusAfterFailedLogin(data, setLoginStatus) {
    setLoginStatus((prev) => (
        /** @type LoginStatus */
        {
            currentStatusCode: data?.status || 'ML',
            customIdField: data?.loginCode?.customIdField,
            previousStatusCodes: appendToPreviousStatusCodes(prev.previousStatusCodes, prev.currentStatusCode),
            errorMessage: data?.description || ''
        }
    ));
}

/**
 * Set the application state and session storage after a successful API call logging in
 * @param {Login} data - The login data returned from the API
 * @param {Dispatch<SetStateAction<string>>} setAccountId - Function to set the state of the user atom
 * @param {Dispatch<SetStateAction<string>>} setBatchId - Function to set the state of the user atom
 * @param {Dispatch<SetStateAction<LoginStatus>>} setLoginStatus - Function to set the state of the user atom
 * @param {Dispatch<SetStateAction<string>>} setProjectId - Function to set the state of the user atom
 * @param {Dispatch<SetStateAction<User>>} setUser - Function to set the state of the user atom
 * @param {function} resetLoginForm - Function to reset the login form to the original state
 * @return {void}
 */
export function setStateAfterSuccessfulLogin(data, setAccountId, setBatchId, setLoginStatus, setProjectId, setUser, resetLoginForm) {
    const accountId = data?.user?.loginState?.account || data?.user?.accountUserRelations?.[0]?.account?.id || null;
    const batchId = data?.user?.loginState?.batch || null;
    const projectId = data?.user?.loginState?.project || null;

    setLoginStatus((prev) => (
        /** @type {LoginStatus} - The updated login status */
        {
            ...prev,
            currentStatusCode: data.status,
            previousStatusCodes: appendToPreviousStatusCodes(prev.previousStatusCodes, prev.currentStatusCode)
        }
    ));

    if (!data?.user?.id) return;

    setUser(data.user);
    setAccountId((prev) => prev || accountId);
    setProjectId((prev) => prev || projectId);
    setBatchId((prev) => prev || batchId);
    resetLoginForm();           // Remove loginForm info because it's too sensitive to store in memory
    storeSessionInfo(data?.id, data?.token?.access, data?.token?.refresh);     
}

/**
 * When a login ID and JWT token was returned by the API, store session info
 * @param {string} loginId - The login uuid returned from the API expressed as a string
 * @param {string} accessToken - The access token returned from the API
 * @param {string} refreshToken - The refresh token returned from the API
 * @return {void}
 */
function storeSessionInfo(loginId, accessToken, refreshToken) {
    if (!loginId || !accessToken || !refreshToken) return;

    const session = {
        'id': loginId,
        'accessToken': accessToken,
        'refreshToken': refreshToken
    };
    const sessionJson = JSON.stringify(session);
    sessionStorage.setItem('session', sessionJson);
}