import { api } from "./query-interceptors";

/**
 * Retrieve information for a list of respondents from the API
 * @param {object} respondentsQueryParams - The parameters to include for filtering and searching respondents
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function respondentsGetQuery(respondentsQueryParams, controller) {
    const expandString = "summaryScores,user";

    const requestOptions = {
        method: 'GET',
        url: `/respondents/`,
        params: {
            ...respondentsQueryParams,
            expand: expandString
        },
        signal: controller.signal
    };
    return api(requestOptions);
}

/**
 * Post a list of new respondent objects to the API
 * @param {Invitee[]} invitees - List of Invitee objects with expanded User property to post to the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function respondentsPostQuery(invitees, controller) {
    const expandString = "user";
    const requestOptions = {
        method: 'POST',
        url: `/respondents/`,
        data: invitees,
        params: {
            expand: expandString
        },
        signal: controller.signal
    };
    return api(requestOptions);
}

/**
 * Retrieve information for a single respondent from the API
 * @param {string} respondentId - The id of the projects to retrieve from the API
 * @param {AbortController} controller - The abort controller to cancel requests
 * @return {Promise} - Returns the axios request promise.
 */
export async function respondentGetQuery(respondentId, controller) {
    const expandString = "scores,summaryScores,user";
    const requestOptions = {
        method: 'GET',
        url: `/respondents/${respondentId}/`,
        params: {
            expand: expandString
        },
        signal: controller.signal
    };
    return api(requestOptions);
}