/**
 * Update a given login code if it is in a list of newly saved login codes
 * @param {LoginCode} originalLoginCode - The original login code
 * @param {LoginCode[]} newlySavedLoginCodes - The list of newly saved login codes
 * @return LoginCode
 */
function updateLoginCode(originalLoginCode, newlySavedLoginCodes) {
    const newlySavedLoginCode = newlySavedLoginCodes.find((newlySavedLoginCode) =>
        newlySavedLoginCode.id === originalLoginCode.id
    )
    return newlySavedLoginCode ? {...newlySavedLoginCode, isSaved: true} : originalLoginCode;

}

/**
 *
 * @param {LoginCode[]} originalLoginCodes - The original list of login codes in the batchLoginCodes atom
 * @param {LoginCode[]} newlySavedLoginCodes - The list of newly saved login codes
 * @return LoginCode[]
 */
export function updateLoginCodesAfterSave(originalLoginCodes, newlySavedLoginCodes) {
    return originalLoginCodes.map((loginCode) =>
        /** @type LoginCode */
        updateLoginCode(loginCode, newlySavedLoginCodes)
    )
}