import { selector } from "recoil";
import { batchIdAtom, userAtom } from "_atoms";
import { batchGetQuery } from "_queries/batch-queries";

/**
 * When there is an active assessment id or a single assessment being administered in the batch, fetch the corresponding
 * assessment information from the API.
 */
const batchSelector = selector({
    key: 'batch',
    get: async ({get}) => {
        /** @type string */
        const batchId = get(batchIdAtom);
        /** @type {User} - Check that there is a valid user atom to verify that the user is currently logged in */
        const user = get(userAtom);
        /** @type AbortController */
        const controller = new AbortController();

        if (!batchId || !user?.id) return null;

        /** @type Promise */
        return batchGetQuery(batchId, controller).then((axiosResponse) => {
            return axiosResponse.data;
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

export { batchSelector };