import { selector } from "recoil";
import { accountIdAtom } from "_atoms";
import { accountGetQuery } from "_queries/account-queries";

/**
 * When there is an active account id, fetch the corresponding account information.
 */
const accountSelector = selector({
    key: 'account',
    get: async ({get}) => {
        /** @type string */
        const accountId = get(accountIdAtom);
        /** @type AbortController */
        const controller = new AbortController();

        if (!accountId) return null;

        /** @type Promise */
        return accountGetQuery(accountId, controller).then((axiosResponse) => {
            /** @type AccountWithProjectsExpanded */
            return axiosResponse.data;
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

export { accountSelector };