import PropTypes from "prop-types";
import React from "react";
import { ChevronLeft, List } from "react-bootstrap-icons";
import './SidebarToggler.css';

const SidebarToggler = ({ toggleSidebar }) => {
    return (
        <>
            <div className="sidebar-toggler-sm" onClick={toggleSidebar}>
                <ChevronLeft size={20} />
            </div>
            <div className="sidebar-toggler-xs" onClick={toggleSidebar}>
                <List size={20} />
            </div>
        </>
    );
}

SidebarToggler.propTypes = {
    toggleSidebar: PropTypes.func.isRequired
};

export default SidebarToggler;
