import { atom } from "recoil";

/**
 * List of respondent uuids that are selected (checked checkboxes) on the batch pages.
 */
const checkedRespondentsAtom = atom({
  key: 'checkedRespondents',
  default: []
});

export { checkedRespondentsAtom };