import { atom } from "recoil";

/**
 * Which modal (if any) to show. A string.
 */
const modalAtom = atom({
  key: 'modal',
  default: ''
});

export { modalAtom };