import { selector } from "recoil";
import { respondentsQueryParamsSelector } from "_selectors";
import { respondentsGetQuery } from "_queries/respondent-queries";

/**
 * The list of respondents for the currently active batch.
 */
const respondentsSelector = selector({
    key: 'respondents',
    get: async ({get}) => {
        /** @type object */
        const respondentsQueryParams = get(respondentsQueryParamsSelector);
        /** @type AbortController */
        const controller = new AbortController();

        if (!respondentsQueryParams) return null;

        /** @type Promise */
        return respondentsGetQuery(respondentsQueryParams, controller).then((axiosResponse) => {
            return axiosResponse.data?.results;
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

export { respondentsSelector };