import { atom } from "recoil";

/**
 * The id of the respondent for which to show results. A UUID.
 */
const respondentIdAtom = atom({
  key: 'respondentId',
  default: null
});

export { respondentIdAtom };